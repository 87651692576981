import produce, { current } from "immer";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { getRepairCodeHrs } from "../../ColumnConfiguration";
import { addNewRow } from "../config";
import moment from 'moment';

export const useClaimInvoiceStore = create(
    // starts with only methods. Objects for specific tables are added to state as needed
    immer((set, get) => ({
        repairHours: 0,
        taxError: [],
        isDataDuplicateExists: false,
        isMarkupErrorExists: false,
        isMaxPriceErrorExists: false,
        isRepairErrorExists: false,
        isNCRepairErrorExists: false,
        replacementDetails: [],
        init: (id, payload, clientId, tax, contract, profile, selectedAsset) => {
            const exists = get()[id];
            // ensure table with ID does not already exist before creating
            if (!exists) {
                set((draft) => {
                    draft[id] = payload;
                    draft['clientId'] = clientId;
                    draft['tax'] = tax;
                    draft['contract'] = contract;
                    draft['profile'] = profile;
                    draft['selectedAsset'] = selectedAsset;
                    draft['initialSelectedAsset'] = selectedAsset?.[0];
                    draft['invId'] = id;
                });
            } else {
                set((draft) => {
                    draft['invId'] = id;
                })
            }
            // else do nothing.
        },

        setInvoiceTaxData: (invoice, fields) => {
            set((draft) => {
                const data = Object.keys(invoice)?.map(e => {
                    return fields.reduce((acc, fl) => {
                        acc = { ...acc, ...{ [fl]: 0 } }
                        return acc;
                    }, {})
                });
                draft['taxData'] = data;
            });
        },

        setTaxError: (array, newItem) => {
            set((draft) => {
                if (Array.isArray(array)) {
                    const exists = array?.find(item => item.name === newItem.name);
                    if (exists) {
                        const val = array?.map(item => item.name === newItem?.name ? newItem : item);
                        draft['taxError'] = val;
                    } else {
                        const val = [...array, newItem]
                        draft['taxError'] = val;
                    }
                }
            });
        },

        resetTaxError: (row) => {
            set((draft) => {
                draft['taxError'] = [];
                draft['currentTaxError'] = '';
            });
        },

        getTaxError: () => {
            return get()['taxError'];
        },

        setReplacementDetails: (value) => {
            set((draft) => {
                draft['replacementDetails'] = [...draft.replacementDetails, value]
            })
        },

        getReplacementDetails: () => {
            return get()['replacementDetails']
        },


        setIsDataDuplicateExists: (value) => {
            set((draft) => {
                draft['isDataDuplicateExists'] = value
            })
        },

        getIsDataDuplicateExists: () => {
            return get()['isDataDuplicateExists']
        },

        setIsMarkupErrorExists: (value) => {
            set((draft) => {
                draft['isMarkupErrorExists'] = value
            })
        },

        getIsMarkupErrorExists: () => {
            return get()['isMarkupErrorExists']
        },

        getIsMaxPriceErrorExists: () => {
            return get()['isMaxPriceErrorExists']
        },

        setMaxPriceErrorExists: (value) => {
            set((draft) => {
                draft['isMaxPriceErrorExists'] = value
            })
        },

        setIsRepairErrorExists: (value) => {
            set((draft) => {
                draft['isRepairErrorExists'] = value
            })
        },

        setIsNCRepairErrorExists: (value) => {
            set((draft) => {
                draft['isNCRepairErrorExists'] = value
            })
        },

        getIsRepairErrorExists: () => {
            return get()['isRepairErrorExists']
        },

        getIsNCRepairErrorExists: () => {
            return get()['isNCRepairErrorExists']
        },

        getCurrentTaxErrorTable: () => {
            return get()['currentTaxError'];
        },

        setRepairHours: async (repairCodeData, invoiceId, tableType, rowIndex, newValues) => {
            const contract = get()['contract'];
            const profile = get()['profile'];
            set((draft) => {
                draft['repairHours'] = repairCodeData?.[0]?.repairHours;
            });
        },

        modifiedCompanionData: (invoiceId, tableType, draft, modifiedData, spliceData) => {
            const currDraft = current(draft);
            const contract = get()['contract'];
            const companionModified = modifiedData.reduce((acc, value, i) => {
                const tableData = currDraft[invoiceId][tableType].data;                
                const selectedComponent = value?.component?.selected?.[0];
                const selectedRepair = value?.repair?.selected?.[0];
                const tableComponentDisplays = tableData?.map(data => data?.component?.selected?.[0]?.display);
                const isPrimaryAttributeExists = contract?.companionRepairMatrix?.some(repairMatrix => 
                    tableComponentDisplays.includes(repairMatrix.primaryAttribute)
                );
                const isNewValueCompanionAttribute = contract?.companionRepairMatrix?.some(repairMatrix => 
                    repairMatrix.companionAttribute === selectedComponent?.display
                );
                const tableRepairsSelected = spliceData?.reduce((acc, value) => {
                    const selectedRepair = value?.repair?.selected ?? [];
                    acc = [...acc, ...(selectedRepair ?? [])]
                    return acc;
                }, [])
    
                const allRepairs = [...tableRepairsSelected]
    
                const isTableRepairDataHasPrimaryAttr = allRepairs.reduce((acc, curr) => {
                    const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(companion => 
                        allRepairs.some(repair => repair.value === companion.primaryRepairActionId                            
                    ));
                    acc = [...acc, ...filteredCompMatrixInfo]
                    return acc;
                }, []);

                const isCurrentCompanion = isTableRepairDataHasPrimaryAttr?.filter(primaryAttr => primaryAttr.companionRepairActionId === selectedRepair?.value)?.length > 0;

                let total;
                if (isCurrentCompanion) {
                    total = 0;
                } else {
                    total = isPrimaryAttributeExists && isNewValueCompanionAttribute ? 0 : value.total;
                }
                acc = [...acc, { ...value, total }]
                return acc;
            }, []);
            return companionModified;
        },

        adjustPercValues: (array, isTableRepairDataHasPrimaryAttr) => {
            const getHours = (arr, index) => {
                if (Array.isArray(arr)) {
                    const currRepairSelectionId = arr?.[index]?.repair?.selected?.[0]?.value;
                    const filterRepairHours = arr?.[index]?.repair?.options?.filter(e => e.repairActionId === currRepairSelectionId)?.[0]?.repairHours;
                    return filterRepairHours;
                }

                const currRepairSelectionId = arr?.repair?.selected?.[0]?.value;
                const filterRepairHours = arr?.repair?.options?.filter(e => e.repairActionId === currRepairSelectionId)?.[0]?.repairHours;
                return filterRepairHours;
            }

            const getPercTotal = (currPerc, rate, hours) => {
                const totalValue = parseFloat(rate) * parseFloat(hours);
                const percentageValue = parseFloat(currPerc);
                const totalPercentage = (totalValue * percentageValue) / 100;
                return parseFloat(totalPercentage.toFixed(2));
            }

            const priorityOrder = ['E', 'D', 'C', 'B', 'A'];

            // Create a new array with updated perc values
            const adjustedArray = array.map(item => ({ ...item }));

            // Handle the case where only two objects exist with the same repairCode
            priorityOrder.forEach(priority => {
                const itemsWithPriority = adjustedArray.filter(item => item?.repairCode?.trim() === priority);
                const repairSelectedValue = itemsWithPriority?.[1]?.['repair']?.selected?.[0].value;
                const isCurrentCompanion = isTableRepairDataHasPrimaryAttr?.filter(e => e.companionRepairActionId === repairSelectedValue)?.length > 0;
                console.log({
                    isCurrentCompanion,
                    itemsWithPriority
                })
                if (itemsWithPriority.length === 2) {
                    itemsWithPriority[0].perc = '100%';
                    itemsWithPriority[1].perc = isCurrentCompanion ? '0%': '75%';
                } else if (itemsWithPriority.length > 2) {
                    itemsWithPriority[0].perc = '100%';
                    itemsWithPriority[1].perc = isCurrentCompanion ? '0%' : '75%';
                    for (let i = 2; i < itemsWithPriority.length; i++) {
                        const repairSelectedValue = itemsWithPriority?.[i]?.['repair']?.selected?.[0].value;
                        const isCurrentCompanion = isTableRepairDataHasPrimaryAttr?.filter(e => e.companionRepairActionId === repairSelectedValue)?.length > 0;
                        itemsWithPriority[i].perc = isCurrentCompanion ? '0%' : '50%';
                    }
                }
            });

            // Ensure the priority order is followed for all other cases
            let has100PercentAssigned = false;
            let has75PercentAssigned = false;
            priorityOrder.forEach(priority => {
                adjustedArray.forEach(item => {
                    console.log({ item })
                    const repairSelectedValue = item?.['repair']?.selected?.[0].value;
                    const isCurrentCompanion = isTableRepairDataHasPrimaryAttr?.filter(e => e.companionRepairActionId === repairSelectedValue)?.length > 0;

                    if (item?.repairCode?.trim() === priority) {
                        if (!has100PercentAssigned) {
                            const hours = getHours(item);
                            item.perc = isCurrentCompanion ? '0%' : '100%';
                            item.total = getPercTotal(100, item.rate, hours);
                            has100PercentAssigned = true;
                        } else if (!has75PercentAssigned && !isCurrentCompanion) {
                            const hours = getHours(item);
                            item.perc = '75%';
                            item.total = getPercTotal(75, item.rate, hours);
                            has75PercentAssigned = true;
                        } else {
                            const hours = getHours(item);
                            item.perc = isCurrentCompanion ? '0%' : '50%';
                            item.total = getPercTotal(50, item.rate, hours);
                        }
                    }
                });
            });
            return adjustedArray;
        },

        getRepairHours: () => {
            return get()['repairHours'];
        },
        getContract: () => {
            return get()['contract'];
        },
        getId: () => {
            return get()['invId'];
        },
        getSelectedAsset: () => {
            return get()['selectedAsset'];
        },
        getInitialSelectedAsset: () => {
            return get()['initialSelectedAsset'];
        },

        updateTotal: (invoiceId, tableType, rowIndex, total) => {
            set((draft) => {
                draft[invoiceId][tableType].data[rowIndex].total = parseFloat(total);
            });
        },

        updateTaxRow: async (invoiceId, tableType, rowIndex, newValues) => {
            set((draft) => {
                if (newValues.hst) {
                    draft[invoiceId][tableType].data[rowIndex].hst = newValues['hst'];
                    draft[invoiceId][tableType].data[rowIndex].total = newValues['total'];
                    return;
                }
                if (newValues.gst) {
                    draft[invoiceId][tableType].data[rowIndex].gst = newValues['gst'];
                    draft[invoiceId][tableType].data[rowIndex].total = newValues['total'];
                    return;
                }
                if (newValues.qst) {
                    draft[invoiceId][tableType].data[rowIndex].qst = newValues['qst'];
                    draft[invoiceId][tableType].data[rowIndex].total = newValues['total'];
                    return;
                }
                if (newValues.pst) {
                    draft[invoiceId][tableType].data[rowIndex].pst = newValues['pst'];
                    draft[invoiceId][tableType].data[rowIndex].total = newValues['total'];
                    return;
                }

                if (newValues.total) {
                    draft[invoiceId][tableType].data[rowIndex].total = parseFloat(newValues['total']);
                    return;
                }
            });
        },

        updateLaborRow: async (invoiceId, tableType, rowIndex, newValues) => {
            let componentData = [];
            let repairData = [];
            let repairCodeData = [];
            const contract = get()['contract'];

            if (newValues.asset) {
                componentData = contract?.repairMatrixInfo?.filter(
                    repairInfo => repairInfo.assetId === newValues.asset?.value
                );
            }

            if (newValues.repair) {
                repairCodeData = contract?.repairMatrixInfo?.filter(
                    repairInfo => 
                        repairInfo.assetId === newValues.repair?.assetId &&
                        repairInfo.repairActionId === newValues.repair?.value
                );
            }

            set((draft) => {
                const currDraft = current(draft);
                const rate = contract?.riskProfile?.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 8)?.[0]?.value;
                const hours = draft[invoiceId][tableType].data[rowIndex].hours;
                const rowRate = draft[invoiceId][tableType].data[rowIndex].rate;
                const rowAssetSelected = draft[invoiceId][tableType].data[rowIndex].asset?.selected?.[0];
                const currRowAssetSelected = currDraft[invoiceId][tableType].data[rowIndex].asset?.selected?.[0];
                const componentOptData = draft[invoiceId][tableType].data[rowIndex].component?.selected?.[0];
                const tableData = currDraft[invoiceId][tableType].data;
                const draftTableData = draft[invoiceId][tableType].data;

                if (newValues.component) {
                    repairData = contract?.repairMatrixInfo?.filter(
                        repairInfo => (repairInfo.attributeId === newValues.component?.value && repairInfo?.assetId === (rowAssetSelected?.assetId || rowAssetSelected?.value))
                    );
                }

                //need clarification from business 
                const clearValues = () => {
                    draft[invoiceId][tableType].data[rowIndex].hours = '';
                }


                if (newValues.handleClear) {
                    draft[invoiceId][tableType].data[rowIndex].repair.options = [];
                    draft[invoiceId][tableType].data[rowIndex].repair.selected = [];
                    draft[invoiceId][tableType].data[rowIndex].component.selected = [];
                    //clearValues();
                    return;
                }
                if (newValues.asset) {
                    draft[invoiceId][tableType].data[rowIndex].asset.selected = [newValues['asset']];
                    draft[invoiceId][tableType].data[rowIndex].component.options = componentData;
                    return;
                }
                if (newValues.component) {
                    const isTableDataHasPrimaryAttr = tableData.reduce((acc, value) => {
                        const selectedComponent = value?.component?.selected?.[0];
                        const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(e => e.primaryAttributeId === selectedComponent?.value);
                        acc = [...acc, ...filteredCompMatrixInfo]
                        return acc;
                    }, []);

                    const isNewValueCompanionAttribute = isTableDataHasPrimaryAttr?.some(e => e.companionAttribute === newValues?.component?.display);
                    draft[invoiceId][tableType].data[rowIndex].component.selected = [newValues['component']];
                    draft[invoiceId][tableType].data[rowIndex].repair.options = repairData;
                    draft[invoiceId][tableType].data[rowIndex].repair.selected = [];
                    if (isNewValueCompanionAttribute) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else {
                        draft[invoiceId][tableType].data[rowIndex].total = parseFloat(rowRate) * parseFloat(hours);
                    }
                    return;
                }
                if (newValues.hours) {
                    const isTableDataHasPrimaryAttr = tableData.reduce((acc, value) => {
                        const selectedComponent = value?.component?.selected?.[0];
                        const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(e => e.primaryAttributeId === selectedComponent?.value);
                        acc = [...acc, ...filteredCompMatrixInfo]
                        return acc;
                    }, []);
                    const isNewValuePrimaryAttribute = isTableDataHasPrimaryAttr?.some(e => e.primaryAttribute === componentOptData?.display);
                    const isNewValueCompanionAttribute = isTableDataHasPrimaryAttr?.some(e => e.companionAttribute === componentOptData?.display);

                    draft[invoiceId][tableType].data[rowIndex].hours = newValues['hours'];
                    draft[invoiceId][tableType].data[rowIndex].showRepairError = newValues['showRepairError'];
                    draft[invoiceId][tableType].data[rowIndex].repairNonCovered = newValues['repairNonCovered'];
                    draft[invoiceId][tableType].data[rowIndex].hrsEdited = newValues['hrsEdited'];
                    if (isNewValueCompanionAttribute) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else {
                        draft[invoiceId][tableType].data[rowIndex].total = parseFloat(rowRate) * parseFloat(newValues['hours']);
                    }
                    return;
                }
                if (newValues.repair) {
                    const isTableDataHasPrimaryAttr = tableData.reduce((acc, value) => {
                        const selectedComponent = value?.component?.selected?.[0];
                        const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(e => e.primaryAttributeId === selectedComponent?.value);
                        acc = [...acc, ...filteredCompMatrixInfo]
                        return acc;
                    }, []);
                    const tableRepairsSelected = tableData?.reduce((acc, value) => {
                        const selectedRepair = value?.repair?.selected ?? [];
                        acc = [...acc, ...(selectedRepair ?? [])]
                        return acc;
                    }, [])

                    const allRepairs = [...tableRepairsSelected, ...[newValues?.repair]]

                    const isTableRepairDataHasPrimaryAttr = allRepairs.reduce((acc, curr) => {
                        const selectedRepair = curr?.value;
                        const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(e => e.primaryRepairActionId === selectedRepair);
                        acc = [...acc, ...filteredCompMatrixInfo]
                        return acc;
                    }, []);


                    const filteredTableData = tableData?.map(e => ((e?.asset?.selected?.[0]?.assetId || e?.asset?.selected?.[0]?.value)));
                    const checkSameAssetsExists = filteredTableData?.filter(e => (e === (currRowAssetSelected?.value || currRowAssetSelected?.assetId)))?.length > 1;
                    const isCurrentCompanion = checkSameAssetsExists && isTableRepairDataHasPrimaryAttr?.filter(e => e.companionRepairActionId === newValues['repair']?.value)?.length > 0;
                    const isCurrentPrimary = checkSameAssetsExists && isTableRepairDataHasPrimaryAttr?.filter(e => e.primaryRepairActionId === newValues['repair']?.value)?.length > 0;
                    const filterRepairsWithCurrent = tableRepairsSelected?.filter(e => e.value === newValues['repair']?.value);
                    const repairHrsFromCodeData = repairCodeData?.filter(e => ((currRowAssetSelected?.assetId || currRowAssetSelected?.value) === e.assetId) && (e.repairActionId === newValues['repair']?.value))?.[0]?.repairHours;
                    const repairCodeNC = repairCodeData?.filter(e => e.repairActionId === newValues['repair']?.value)?.[0]?.repairHours === 0 ? true : false;
                    const customHours = repairCodeData?.[0]?.repairHours === 0 ? 0 : '0.5';
                    const isNewValuePrimaryAttribute = isTableDataHasPrimaryAttr?.some(e => e.primaryAttribute === componentOptData?.display);
                    const isNewValueCompanionAttribute = isTableDataHasPrimaryAttr?.some(e => e.companionAttribute === componentOptData?.display);

                    if (isNewValueCompanionAttribute) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else if (repairCodeData?.[0]?.repairHours === 0) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else if (isCurrentCompanion) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else {
                        draft[invoiceId][tableType].data[rowIndex].total = parseFloat(rowRate) * parseFloat(customHours);
                    }
                    draft[invoiceId][tableType].data[rowIndex].repair.selected = Object.keys(newValues['repair'])?.length > 0 ? [newValues['repair']] : [];
                    draft[invoiceId][tableType].data[rowIndex].hours = customHours;
                    draft[invoiceId][tableType].data[rowIndex].repairHours = repairHrsFromCodeData;
                    draft[invoiceId][tableType].data[rowIndex].showDuplicateRepairError = newValues['showDuplicateRepairError'];
                    draft[invoiceId][tableType].data[rowIndex].hrsEdited = false;
                    get().setRepairHours(repairCodeData, invoiceId, tableType, rowIndex);
                    return;
                }
            });
        },

        updateTripRow: async (invoiceId, tableType, rowIndex, newValues) => {            
            set((draft) => {
                const data = draft[invoiceId].trip.data;
                const tripRate = draft[invoiceId][tableType].data[rowIndex].tripRate;
                if (newValues.reason) {
                    draft[invoiceId][tableType].data[rowIndex].reason.selected = [newValues['reason']];
                    draft[invoiceId][tableType].data[rowIndex].total = parseFloat(tripRate);
                    return;
                }

                for (const key in newValues) {
                    data[key] = newValues[key];
                }
            });
        },

        updateRefrigerantRow: async (invoiceId, tableType, rowIndex, newValues) => {
            set((draft) => {
                const data = draft[invoiceId][tableType]?.data[rowIndex];
                const price = draft[invoiceId][tableType]?.data[rowIndex]?.price;
                const qty = draft[invoiceId][tableType].data[rowIndex].qty;
                if (newValues.refrigerantType) {
                    draft[invoiceId][tableType].data[rowIndex].refrigerantType.selected = [newValues['refrigerantType']];
                    //draft[invoiceId][tableType].data[rowIndex].total = 1 * );
                    return;
                }

                if (newValues.qty) {
                    draft[invoiceId][tableType].data[rowIndex].qty = newValues['qty'];
                    draft[invoiceId][tableType].data[rowIndex].total = parseFloat(price) * parseFloat(newValues.qty);
                    return
                }

                if (newValues.price || newValues.price == 0) {
                    draft[invoiceId][tableType].data[rowIndex].price = parseFloat(newValues.price);
                    draft[invoiceId][tableType].data[rowIndex].showMaxPriceError = newValues['showMaxPriceError'];
                    draft[invoiceId][tableType].data[rowIndex].total = (parseFloat(newValues.price) * parseFloat(qty));
                    return
                }

                if (newValues.hasOwnProperty('showMaxPriceError')) {
                    draft[invoiceId][tableType].data[rowIndex].showMaxPriceError = newValues['showMaxPriceError'];
                    return;
                }

                for (const key in newValues) {
                    data[key] = newValues[key];
                }
            });
        },

        updatePartsRow: async (invoiceId, tableType, rowIndex, newValues) => {
            const contract = get()['contract'];
            const invId = get()['invId'];
            let partDescriptionData = [];
            const coverageType = contract.riskProfile.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 28)?.[0]?.value;

            if (newValues.asset) {
                partDescriptionData = contract?.assets?.filter(
                    partData => partData.assetId === newValues.asset?.value
                )?.[0]?.parts;
            }

            const coverageTypePartsCalculation = (coverageType === "18" ||
                coverageType === "19" ||
                coverageType === "16" ||
                coverageType === "5" ||
                coverageType === "21" ||
                coverageType === "22")

            set((draft) => {
                const price = draft[invoiceId][tableType]?.data[rowIndex]?.price;
                const qty = draft[invoiceId][tableType].data[rowIndex].qty;
                const total = (newValues.oem === true) ? '0.00' : parseFloat(price) * parseFloat(qty);
                const rowOemValue = draft[invoiceId][tableType].data[rowIndex].oem;

                if (newValues.asset) {
                    draft[invoiceId][tableType].data[rowIndex].asset.selected = [newValues['asset']];
                    return;
                }
                if (newValues.price || newValues.price == 0) {
                    draft[invoiceId][tableType].data[rowIndex].price = parseFloat(newValues.price);
                    draft[invoiceId][tableType].data[rowIndex].total = parseFloat(newValues.price) * parseFloat(qty);
                    if (coverageTypePartsCalculation) {
                        if (rowOemValue) {
                            draft[invoiceId][tableType].data[rowIndex].total = parseFloat('0.00');
                        } else if (!rowOemValue) {
                            draft[invoiceId][tableType].data[rowIndex].total = parseFloat(newValues.price) * parseFloat(qty);
                        }
                    }
                    return;
                }

                if (newValues.partNumber) {
                    draft[invId][tableType].data[rowIndex].partNumber = newValues['partNumber'];
                    return;
                }

                if (newValues.description) {
                    draft[invoiceId][tableType].data[rowIndex].description.selected = [newValues['description']];
                    return;
                }

                if (newValues.hasOwnProperty('oem')) {
                    if (newValues.oem || !newValues.oem) {
                        draft[invoiceId][tableType].data[rowIndex].oem = newValues['oem'];
                        if (newValues.oem === true && coverageTypePartsCalculation) {
                            draft[invoiceId][tableType].data[rowIndex].total = parseFloat('0.00');
                        } else if (newValues.oem === false && coverageTypePartsCalculation) {
                            draft[invoiceId][tableType].data[rowIndex].total = parseFloat(price) * parseFloat(qty);
                        }
                        return;
                    }
                }
            });
        },

        updatePartsMarkupRow: async (invoiceId, tableType, rowIndex, newValues) => {
            const contract = get()['contract'];
            const markupData = contract.riskProfile.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 16)?.[0]?.value;
            const coverageType = contract.riskProfile.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 28)?.[0]?.value;
            const invId = get()['invId'];
            const assetPurchaseDate = moment(contract.contractAssetModel[0].assetPurchaseDate).local().format(contract.country == "US" ? "L" : "MM/DD/YYYY")
            const partsWarranty = contract.contractAssetModel[0]?.oemPartsCoverage; //1,2,3,4,5

            let currentDate = moment();

            const monthDifference = currentDate.diff(assetPurchaseDate, 'months');
            const partsWarrantyInMonths = partsWarranty * 12
            const wFlag = monthDifference <= partsWarrantyInMonths

            let partDescriptionData = [];

            if (newValues.asset) {
                partDescriptionData = contract?.assets?.filter(
                    partData => partData.assetId === newValues.asset?.value
                )?.[0]?.parts;
            }

            const coverageTypePriceCalculation = (coverageType === "10" || coverageType === "4" || coverageType === "29");

            const coverageTypeTotalCalculation = (coverageType === "19" ||
                coverageType === "20" ||
                coverageType === "28" ||
                coverageType === "23" ||
                coverageType === "25" ||// parts and markup, When OEM Checked,
                coverageType === "26" || 
                coverageType === "27")// parts and markup, When OEM Checked,

            const partsStartRule = ( coverageType === "27" || coverageType === "13") //parts and markup, OEM checkbox with install date 
            const laborStartRule = ( coverageType === "26") //parts and markup, OEM checkbox with install date 

            const coverageTypePartsCalculation = (coverageType === "18" ||
                coverageType === "19" ||
                coverageType === "16" ||
                coverageType === "5" ||
                coverageType === "21" ||
                coverageType === "22" ||
                coverageType === "13" ||// parts and Total only , OEM checked 0 not checked parts is total
                coverageType === "27") // parts and Total only , OEM checked 0 not checked parts is total

            set((draft) => {
                const data = draft[invoiceId]?.partsMarkup?.data;
                const qty = draft?.[invoiceId]?.[tableType]?.data?.[rowIndex]?.qty;
                const rowPrice = draft[invoiceId]?.[tableType]?.data?.[rowIndex]?.price;
                const rowMarkupValue = draft[invoiceId]?.[tableType]?.data?.[rowIndex]?.markup;
                const rowOemValue = draft[invoiceId]?.[tableType]?.data?.[rowIndex]?.oem;

                if (newValues.asset) {
                    draft[invoiceId][tableType].data[rowIndex].asset.selected = [newValues['asset']];
                    draft[invoiceId][tableType].data[rowIndex].description.options = partDescriptionData;
                    return;
                }

                if (newValues.partNumber || newValues.partNumber === '') {
                    draft[invId][tableType].data[rowIndex].partNumber = newValues['partNumber'];
                    return;
                }

                if (newValues.description) {
                    draft[invoiceId][tableType].data[rowIndex].description.selected = [newValues['description']];
                    return;
                }

                if (newValues.price || newValues.price == 0) {
                    draft[invoiceId][tableType].data[rowIndex].price = parseFloat(newValues.price);
                    draft[invoiceId][tableType].data[rowIndex].markup = parseFloat(rowMarkupValue);
                    if (coverageTypePriceCalculation) {
                        draft[invoiceId][tableType].data[rowIndex].total = parseFloat(rowMarkupValue);
                        return;
                    }
                    if (rowOemValue === true && coverageTypePartsCalculation) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                        return;
                    } 
                    if (coverageTypeTotalCalculation ) {
                        if (rowOemValue) {
                            draft[invoiceId][tableType].data[rowIndex].total = parseFloat(rowMarkupValue);
                            return;
                        }
                    }
                    draft[invoiceId][tableType].data[rowIndex].total = (parseFloat(rowMarkupValue) + parseFloat(newValues.price)) * parseFloat(qty);
                    return;
                }

                if (newValues.hasOwnProperty('oem')) {
                    if (newValues.oem || !newValues.oem) {
                        draft[invoiceId][tableType].data[rowIndex].oem = newValues['oem'];
                        draft[invoiceId][tableType].data[rowIndex].markup = rowMarkupValue;
                        if ((newValues.oem === true && coverageTypeTotalCalculation) || coverageTypePriceCalculation) {
                            draft[invoiceId][tableType].data[rowIndex].total = parseFloat(rowMarkupValue);
                        } else if (newValues.oem === false && coverageTypeTotalCalculation) {
                            draft[invoiceId][tableType].data[rowIndex].total = (parseFloat(rowMarkupValue) + parseFloat(rowPrice)) * parseFloat(qty);
                        } else if (newValues.oem === true && coverageTypePartsCalculation) {
                            draft[invoiceId][tableType].data[rowIndex].total = 0;
                        } else if (newValues.oem === false && coverageTypePartsCalculation) {
                            draft[invoiceId][tableType].data[rowIndex].total = parseFloat(rowPrice);
                        }
                    }
                }

                if (newValues.markup || newValues.markup == 0) {
                    draft[invoiceId][tableType].data[rowIndex].markup = newValues['markup'];
                    draft[invoiceId][tableType].data[rowIndex].showMarkupError = newValues['showMarkupError'];
                    if (coverageTypePriceCalculation) {
                        draft[invoiceId][tableType].data[rowIndex].total = newValues.markup === 0 ? '0' : parseFloat(newValues.markup);
                    } else if (coverageTypeTotalCalculation) {
                        if (rowOemValue) {
                            draft[invoiceId][tableType].data[rowIndex].total = parseFloat(newValues.markup);
                        } else {
                            draft[invoiceId][tableType].data[rowIndex].total = (parseFloat(newValues.markup) + parseFloat(rowPrice)) * parseFloat(qty);
                        }
                    } else if (rowOemValue === true && coverageTypePartsCalculation) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else {
                        draft[invoiceId][tableType].data[rowIndex].total = parseFloat(newValues.markup);
                    }
                    return;
                }

                if (newValues.qty) {
                    draft[invoiceId][tableType].data[rowIndex].qty = newValues['qty'];
                    draft[invoiceId][tableType].data[rowIndex].total = parseFloat(rowMarkupValue) * parseFloat(newValues.qty);
                    return
                }

                if (newValues.hasOwnProperty('showMarkupError')) {
                    draft[invoiceId][tableType].data[rowIndex].showMarkupError = newValues['showMarkupError'];
                    return;
                }

            });
        },

        updateRow: (invoiceId, tableType, rowIndex, newValues) => {
            set((draft) => {
                const record = draft[invoiceId][tableType]?.data[rowIndex];
                for (const key in newValues) {
                    record[key] = newValues[key];
                }
            });
        },

        // IDEAL would be to avoid logic specific to one table or another inside store.
        updateLaborSelectRateTable: async (invoiceId, tableType, rowIndex, newValues) => {
            let componentData = [];
            let repairData = [];
            let repairCodeData = [];
            const contract = get()['contract'];
            const validRepairCodes = ["A", "B", "C", "D", "E", "NC"];

            const selectedAsset = get().getSelectedAsset()
            const currentContractAsset = selectedAsset?.length ? contract.contractAssetModel?.filter(contractAsset => contractAsset.contractAssetId === selectedAsset?.[0]) : [];

            const isValidRepairCode = (repairCode) => validRepairCodes.includes(repairCode?.trim());
            const tableData = get().getTableData(invoiceId, tableType);

            if (newValues.asset) {
                componentData = contract?.repairMatrixInfo?.filter(
                    repairInfo => repairInfo.assetId === newValues.asset?.value
                );
            }
            if (newValues.component) {
                const selectedAssetId = currentContractAsset?.length > 0 ? currentContractAsset[0].assetId : newValues.component?.assetId
                repairData = contract?.repairMatrixInfo?.filter(
                    repairInfo => 
                        repairInfo.assetId === selectedAssetId &&
                        repairInfo.attributeId === newValues.component?.value
                );
            }
            if (newValues.repair) {
                const selectedAssetId = currentContractAsset?.length > 0 ? currentContractAsset[0].assetId : newValues.repair?.assetId
                repairCodeData = contract?.repairMatrixInfo?.filter(
                    repairInfo => 
                        repairInfo.assetId ===  selectedAssetId &&
                        repairInfo.repairActionId === newValues.repair?.value
                );
            }

            const getInstances = (key, value) => {
                return tableData?.map((obj, index) => obj[key] === value ? index : -1).filter(index => index !== -1);
            }

            //Needs rework
            set((draft) => {
                const currDraft = current(draft);
                const rate = contract?.riskProfile?.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 8)?.[0]?.value;
                const hours = repairCodeData?.[0]?.repairHours
                const componentOptData = draft[invoiceId][tableType].data[rowIndex].component?.selected?.[0];
                const rowAssetSelected = draft[invoiceId][tableType].data[rowIndex].asset?.selected?.[0];
                const currRowAssetSelected = currDraft[invoiceId][tableType].data[rowIndex].asset?.selected?.[0];

                const tableData = currDraft[invoiceId][tableType].data;
                const getPercentage = (currentRepairCode) => {
                    const currRepairCodeInstances = getInstances('repairCode', currentRepairCode);
                    if (!isValidRepairCode(currentRepairCode)) {
                        return '';
                    } else if (currentRepairCode === 'NC') {
                        return '0%';
                    } else if (tableData?.length === 1) {
                        return '100%';
                    } else if (tableData?.length === 2 && currentRepairCode) {
                        //Show 100% and 75% based on repair codes priority
                        draft[invoiceId][tableType].data[0].perc =
                            isValidRepairCode(tableData[0]?.repairCode)
                                ? tableData[0]?.repairCode?.charCodeAt(0) > currentRepairCode?.charCodeAt(0)
                                    ? '100%' : '75%'
                                : ''
                        if (rowIndex === 1) {
                            if ((currentRepairCode?.charCodeAt(0) > tableData[0]?.repairCode?.charCodeAt(0)) ||
                                !isValidRepairCode(tableData[0]?.repairCode)
                            ) {
                                return '100%';
                            } else {
                                return '75%';
                            }
                        }
                    } else if (tableData.length > 2) {
                        //After adding more than 2 rows, determine percentage based on their repair codes
                        // highest (100%) and second highest(75%) and REST (50%)
                        draft[invoiceId][tableType].data.slice(0, -1).forEach((data, index) => {
                            //filter array to show 100% for priority repair code
                            const highestPriorityCodes = tableData.slice(0, -1).filter(
                                repairData => repairData?.repairCode !== tableData[index]?.repairCode
                            )

                            //filter array to show 75% for second priority repair code
                            const secondPriorityCodes = tableData.slice(0, -1).filter(
                                repairData => repairData?.repairCode !== "E" && repairData?.repairCode !== tableData[index]?.repairCode
                            )

                            if (!isValidRepairCode(tableData[index]?.repairCode)) {
                                draft[invoiceId][tableType].data[index].perc = ''
                            } else {
                                draft[invoiceId][tableType].data[index].perc =
                                    tableData[index]?.repairCode?.charCodeAt(0) > currentRepairCode?.charCodeAt(0) &&
                                        highestPriorityCodes.every(
                                            repairData => repairData?.repairCode?.charCodeAt(0) < tableData[index]?.repairCode?.charCodeAt(0)
                                        ) ? '100%' :
                                        secondPriorityCodes.every(
                                            repairData => repairData?.repairCode?.charCodeAt(0) < tableData[index]?.repairCode?.charCodeAt(0)
                                        ) ? '75%' :
                                            '50%'
                            }
                        })
                        //This is current row added
                        if (rowIndex >= 2) {
                            const highestPriorityCodes = tableData.slice(0, -1)?.filter(
                                repairData => repairData?.repairCode !== currentRepairCode
                            )
                            const secondPriorityCodes = tableData.slice(0, -1)?.filter(
                                repairData => repairData?.repairCode !== "E" && repairData?.repairCode !== currentRepairCode
                            )

                            if (highestPriorityCodes.every(repairData => repairData?.repairCode?.charCodeAt(0) < currentRepairCode?.charCodeAt(0))) {
                                return '100%'
                            } else if (secondPriorityCodes.every(repairData => repairData?.repairCode?.charCodeAt(0) < currentRepairCode?.charCodeAt(0))) {
                                return '75%'
                            } else {
                                return '50%'
                            }
                        }
                    } else {
                        return ''
                    }
                }
                const clearValues = () => {
                    draft[invoiceId][tableType].data[rowIndex].repairCode = '';
                    draft[invoiceId][tableType].data[rowIndex].rate = '';
                    draft[invoiceId][tableType].data[rowIndex].perc = '';
                    draft[invoiceId][tableType].data[rowIndex].total = '';
                }
                if (newValues.handleClear) {
                    draft[invoiceId][tableType].data[rowIndex].repair.options = [];
                    draft[invoiceId][tableType].data[rowIndex].repair.selected = [];
                    draft[invoiceId][tableType].data[rowIndex].component.selected = [];
                    clearValues();
                    return;
                }


                if (newValues.asset) {
                    const currentContractAsset = contract.contractAssetModel?.filter(contractAsset => contractAsset.assetId === newValues.asset?.value);
                    draft['selectedAsset'] = [currentContractAsset[0].contractAssetId];
                    draft[invoiceId][tableType].data[rowIndex].asset.selected = [newValues['asset']];
                    draft[invoiceId][tableType].data[rowIndex].component.options = componentData;
                }
                if (newValues.component) {
                    draft[invoiceId][tableType].data[rowIndex].component.selected = [newValues['component']];
                    draft[invoiceId][tableType].data[rowIndex].repair.options = repairData;
                    draft[invoiceId][tableType].data[rowIndex].repair.selected = [];
                    clearValues();
                }

                if (newValues.repair) {
                    const isTableDataHasPrimaryAttr = tableData.reduce((acc, value) => {
                        const selectedComponent = value?.component?.selected?.[0];
                        const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(e => e.primaryAttributeId === selectedComponent?.value);
                        acc = [...acc, ...filteredCompMatrixInfo]
                        return acc;
                    }, []);
                    const tableRepairsSelected = tableData?.reduce((acc, value) => {
                        const selectedRepair = value?.repair?.selected ?? [];
                        acc = [...acc, ...(selectedRepair ?? [])]
                        return acc;
                    }, [])

                    const allRepairs = [...tableRepairsSelected, ...[newValues?.repair]]

                    const isTableRepairDataHasPrimaryAttr = allRepairs.reduce((acc, curr) => {
                        const selectedRepair = curr?.value;
                        const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(e => e.primaryRepairActionId === selectedRepair);
                        acc = [...acc, ...filteredCompMatrixInfo]
                        return acc;
                    }, []);


                    const filteredTableData = tableData?.map(e => ((e?.asset?.selected?.[0]?.assetId || e?.asset?.selected?.[0]?.value)));
                    const checkSameAssetsExists = filteredTableData?.filter(e => (e === (currRowAssetSelected?.value || currRowAssetSelected?.assetId)))?.length > 1;
                    const isCurrentCompanion = isTableRepairDataHasPrimaryAttr?.filter(e => e.companionRepairActionId === newValues['repair']?.value)?.length > 0;
                    const isCurrentPrimary = checkSameAssetsExists && isTableRepairDataHasPrimaryAttr?.filter(e => e.primaryRepairActionId === newValues['repair']?.value)?.length > 0;
                    const filterRepairsWithCurrent = tableRepairsSelected?.filter(e => e.value === newValues['repair']?.value);
                    const isNewValueCompanionAttribute = isTableDataHasPrimaryAttr?.some(e => e.companionAttribute === componentOptData?.display);
                    draft[invoiceId][tableType].data[rowIndex].repair.selected = [newValues['repair']];
                    draft[invoiceId][tableType].data[rowIndex].repairCode = repairCodeData?.[0]?.repairCode ?? '';
                    draft[invoiceId][tableType].data[rowIndex].rate = rate;
                    draft[invoiceId][tableType].data[rowIndex].showDuplicateRepairError = newValues['showDuplicateRepairError'];
                    const repairCode = repairCodeData?.[0]?.repairCode;
                    const currPerc = getPercentage(repairCodeData?.[0]?.repairCode);
                    draft[invoiceId][tableType].data[rowIndex].perc = (isCurrentCompanion || repairCode === 'NC')  ? '0%' : currPerc;
                    const totalValue = parseFloat(rate) * parseFloat(hours);
                    const percentageValue = parseFloat(currPerc);
                    const totalPercentage = (totalValue * percentageValue) / 100;
                    if (isNewValueCompanionAttribute) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else if (repairCodeData?.[0]?.repairHours === 0) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else if (isCurrentCompanion) {
                        draft[invoiceId][tableType].data[rowIndex].total = 0;
                    } else {
                        draft[invoiceId][tableType].data[rowIndex].total = parseFloat(totalPercentage);
                    }

                    const currDraft = current(draft);
                    const data = currDraft[invoiceId].laborSelectRate.data;
                    const modifiedData = get().adjustPercValues(data, isTableRepairDataHasPrimaryAttr);

                    const companionModified = modifiedData.reduce((acc, value, i) => {
                        const tableData = currDraft[invoiceId][tableType].data;
                        const selectedComponent = value?.component?.selected?.[0];
                        const selectedRepair = value?.repair?.selected?.[0];
                        const tableComponentDisplays = tableData?.map(e => e?.component?.selected?.[0]?.display);
                        const isPrimaryAttributeExists = contract?.companionRepairMatrix?.some(e => tableComponentDisplays.includes(e.primaryAttribute));
                        const isNewValueCompanionAttribute = contract?.companionRepairMatrix?.some(e => e.companionAttribute === selectedComponent?.display);
                        const tableRepairsSelected = tableData?.reduce((acc, value) => {
                            const selectedRepair = value?.repair?.selected ?? [];
                            acc = [...acc, ...(selectedRepair ?? [])]
                            return acc;
                        }, [])
                        // const isTableRepairDataHasPrimaryAttr = contract?.companionRepairMatrix?.filter(e => e.primaryRepairActionId === selectedRepair?.value);
                        const allRepairs = [...tableRepairsSelected, ...[newValues?.repair]]
                        const isTableRepairDataHasPrimaryAttr = allRepairs.reduce((acc, curr) => {
                            const selectedRepair = curr?.value;
                            const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(e => e.primaryRepairActionId === selectedRepair);

                            acc = [...acc, ...filteredCompMatrixInfo]
                            return acc;
                        }, []);
                        const isCurrentCompanion = isTableRepairDataHasPrimaryAttr?.filter(e => e.companionRepairActionId === selectedRepair?.value)?.length > 0;
                        const isCurrentPrimary = checkSameAssetsExists && isTableRepairDataHasPrimaryAttr?.filter(e => e.primaryRepairActionId === selectedRepair?.value)?.length > 0;
                        let total;
                        if (isCurrentCompanion) {
                            total = 0;
                        } else {
                            total = isPrimaryAttributeExists && isNewValueCompanionAttribute ? 0 : value.total;
                        }
                        acc = [...acc, { ...value, total }]
                        return acc;
                    }, []);

                    draft[invoiceId].laborSelectRate.data = companionModified;
                }

            })
        },
        addTableRow: (invoiceId, tableType, fetchedRowValues, contract) => {
            set((draft) => {
                draft[invoiceId][tableType].data.push(
                    addNewRow(tableType, invoiceId, { ...fetchedRowValues }, contract)
                );
            });
        },

        currentInvoice: (currentInvoiceId) => {
            return get()[currentInvoiceId];
        },

        getRow: (staticRow, index) => {
            const { invoiceId, tableType } = staticRow;
            return get()[invoiceId]?.[tableType]?.data?.[index];
        },

        deleteRow: (invoiceId, tableType, index) => {
            const data = get()[invoiceId]?.[tableType]?.data;
            const spliceData = data.filter((e, i) => i !== index);
            const contract = get()['contract'];

            const tableRepairsSelected = spliceData?.reduce((acc, value) => {
                const selectedRepair = value?.repair?.selected ?? [];
                acc = [...acc, ...(selectedRepair ?? [])]
                return acc;
            }, [])

            const allRepairs = [...tableRepairsSelected]

            const isTableRepairDataHasPrimaryAttr = allRepairs.reduce((acc, curr) => {
                const filteredCompMatrixInfo = contract?.companionRepairMatrix?.filter(e => allRepairs.some(s => s.value === e.primaryRepairActionId));
                acc = [...acc, ...filteredCompMatrixInfo]
                return acc;
            }, []);

            let modifiedData;
            let companionModified;
            
            if (tableType === 'laborSelectRate') {
                modifiedData = get().adjustPercValues(spliceData, isTableRepairDataHasPrimaryAttr);                
            }
            set((draft) => {
                if (tableType === 'laborSelectRate') {
                    companionModified = get().modifiedCompanionData(invoiceId, tableType, draft, modifiedData, spliceData);
                }
                draft[invoiceId][tableType].data = tableType === 'laborSelectRate' ? companionModified : spliceData;
            });
        },

        getTableData: (invoiceId, tableType) => {
            return get()[invoiceId]?.[tableType]?.data;
        }
    }))
);
